.bioText {
  text-align: center;
  color: white;
  font-family: Play;
  font-size: 20px;
}

section {
  width: 70%;
}

.familyPhoto {
  width: 100%;
  margin-bottom: 100px;
}

@media (max-width: 500px) {
  section {
    width: 90%;
  }
}

@media (max-width: 450px) {
  .bioText {
    font-size: 15px;
  }
}
