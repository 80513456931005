nav {
	width: 100%;
  height: 50px;
  background: #15151E;
	top: 0;
}

ul {
	padding: 0;
	margin: 0;
	display: flex;
	justify-content: center;
}

li {
	margin-right: 20px;
	margin-left: 20px;
}

/* TODO: remove class and replace with div span */
.hamSpan {
  background-color: #143DF5;
  width: 20px;
	height: 2px;
	margin: auto;
}

.ham {
  cursor: pointer;
  padding: 5px;
  margin-right: 15px;
  border: 1px solid #00C33C;
  height: 35px;
  width: 35px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
}
.dropNav {
	z-index: 10px;
	width: 100%;
  top: 0px;
  left: 0px;
  flex-direction: column;
  position: absolute;
  text-align: center;
  margin-top: 194px;
  background : #15151E;
  border-bottom: 1px solid #143DF5;
}
.navbarLogo {
  cursor: pointer;
  margin-top: 55px;
  height: 100px;
}
.mobileNavLogo {
  cursor: pointer;
  height: 40px;
  padding-left: 10px;
  margin-top: auto;
  margin-bottom: auto;
}

.navLink:hover {
  color: #143DF5;
}

.navLink {
  color: white;
  cursor: pointer;
  padding: 15px;
}

@media (max-width: 800px) {
  .navLogo2 {
    height: 70px;
  }
  .ham {
    width: 50px;
    height: 50px;
  }
  .hamSpan {
    width: 30px;
  }
  nav {
    height: 100px;
  }
  .dropNav {
    margin-top: 100px;
	}
	.mobileNavLogo {
		height: 75px;
	}
}
@media (max-width: 700px) {
  .navLogo2 {
    height: 50px;
  }
  .ham {
    width: 30px;
    height: 30px;
  }
  .hamSpan {
    width: 20px;
  }
  nav {
    height: 50px;
  }
  .dropNav {
    margin-top: 49px;
	}
	.mobileNavLogo {
		height: 40px;
	}
}
