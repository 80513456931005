#darkDiv {
  background-color:rgba(0, 0, 0, 0.7);
  position: relative;
  padding-top: 120px;
}

.padding0 {
  padding: 0;
}

.pS20 {
  padding-left: 20px;
  padding-right: 20px;
}

.icon {
  color: white;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
}

.icon-div {
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 30px;
  height: 50px;
  width: 50px;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  display: flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
}
.hyphon {
  margin-left: 5px;
  margin-right: 5px;
}
.icon-div:hover {
  background: linear-gradient(to right, #64549A, #08A975);;
}

.icon-logo {
  margin-left: 5px;
  margin-right: 5px;
}
.button {
  margin-left: auto;
  margin-right: auto;
}
.footer-text {
  color: white;
  font-size: 20px;
  font-family: exo-medium;
  margin: 0;
}
.wysidio-link {
  margin-left: 5px;
  text-decoration: none;
  color: #367F87;
  font-size: 20px;
  font-family: exo-medium;
}
.rowCol {
  display: flex;
  flex-direction: row;
}
.mSA {
  margin-left: auto;
  margin-right: auto;
}
.mainEpk {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  font-family: exo-light;
}
.song1 {
  height: 250px;
  width: 40%;
  display: flex;
}
.section {
  padding-top: 30px;
}
.pT30 {
  padding-top: 30px;
}
.secHead {
  margin: 0;
  border: none;
  color: #E0E0E0;
  font-size: 40px;
  text-align: center;
  font-family: exo-light;
}
.list {
  color: #E0E0E0;
  font-size: 25px;
  margin: 0;
}
.bigBullet {
  padding-bottom: 15px;
}
.bioContent {
  color: #E0E0E0;
  font-size: 18px;
}
.bioHeader {
  margin: 0;
  color: #E0E0E0;
  font-size: 35px;
}
.mediaLink {
  color: #367F87;
  font-size: 25px;
}
#logoImg {
  width: 80%;
  display: flex;
  padding-top: 100px;
  padding-bottom: 100px
}
.title {
  border-top: 2px solid rgba(255, 255, 255, .5);
  height: 99px;
  border-bottom: 2px solid rgba(255, 255, 255, .5);
}
.footer {
  color: #E0E0E0;
  padding-bottom: 30px;
}
.wysidio {
  width: 50px;
}
.press {
  border-bottom: 2px solid rgba(255, 255, 255, .5);
}
#epkTitle {
  text-align: center;
  color: white;
  margin-top: 30px;
  margin-bottom: 30px;
  font-family: exo-medium;
  font-size: 30px;
}
.pressPic {
  display: flex;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
}
.social {
  padding-bottom: 30px;
  padding-top: 30px;
  align-items: center;
  justify-content: flex-end;
}
.socialLink {
  color: rgba(0, 0, 0, 0);
}
#logoHeader {
  width: 100%;
  margin-bottom: 60px;
}
.pressKitDl {
  cursor: pointer;
  width: 400px;
  display: flex;
  flex-direction: column;
  color: rgba(0, 0, 0, 0);
  margin-bottom: 25px;
}
.download {
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  width: 400px;
  height: 80px;
  border-radius: 8px;
  background: linear-gradient(to right, #64549A, #08A975);
}
.hvrGrow {
    display: inline-block;
    vertical-align: middle;
    transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    transition-duration: 0.3s;
    transition-property: transform;
}
.hvrGrow:hover,
.hvrGrow:focus,
.hvrGrow:active {
    transform: scale(1.1);
    -webkit-box-shadow: -1px 1px 84px 0px rgba(255,255,255,0.79);
    -moz-box-shadow: -1px 1px 84px 0px rgba(255,255,255,0.79);
    box-shadow: -1px 1px 84px 0px rgba(255,255,255,0.79);
}
.button-text {
  color: silver;
  font-size: 20px;
  text-align: center;
}
.album {
  height: 400px;
  width: 100%;
}
@media (max-width: 1250px) {
  .rowCol {
    flex-direction: column;
  }
  .song1 {
    width: 100%;
    height: 450px;
  }
  .pT50Col {
    margin-bottom: 50px;
  }
  .social {
    justify-content: center;
  }
}
@media (max-width: 900px) {
  .mediaLink {
    font-size: 20px;
  }
}
@media (max-width: 800px) {
  .mainEpk {
    width: 80%;
  }
  .song1 {
    width: 100%;
    height: 400px;
  }
  .button-text {
    font-size: 16px;
  }
}
@media (max-width: 600px) {
  .mainEpk {
    width: 90%;
  }
  .pressPic {
    width: 100%;
  }
  .song1 {
    width: 100%;
  }
  #logo1 {
    width: 90%;
  }
  .mediaLink {
    font-size: 14px;
  }
  #logoHeader {
    height: 350px;
  }
  .button-text {
    font-size: 13px;
  }
  .download {
    width: 80%;
    height: 50px;
  }
}
@media (max-width: 450px) {
  .icon-div {
    width: 30px;
    height: 30px;
  }
  .mediaLink {
    font-size: 11px;
  }
  .secHead {
    font-size: 25px;
  }
  .list {
    font-size: 14px;
  }
  .song1 {
    width: 80%;
    height: 250px;
  }
  #epk-title {
    font-size: 25px;
  }
}
@media (max-width: 400px) {
  .mediaLink {
    font-size: 10px;
  }
  .list {
    font-size: 12px;
  }
}

@media (max-width: 320px) {
  .secHead {
    font-size: 20px;
  }
  .bioContent {
    font-size: 15px;
  }
}
