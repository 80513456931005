.socialLink {
	color: rgba(0, 0, 0, 0);
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px !important;
	width: 100px !important;
}

.socials {
  height: 50px;
  width: 300px;
}

.icon {
  color: white;
}

footer {
  margin-top: auto;
  width: 100%;
  background-color: #15151E;
}

.fa-superpowers {
  margin-left: 0;
}