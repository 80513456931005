.videoContainer {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
}

.video {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
  height: 315px;
  width: 40%;
}

h3 {
  text-align: center;
  color: #C4C4C4;
  font-family: Orbitron;
  margin-bottom: 100px;
  margin-top: 0;
}

.flyerCarousel {
  margin-bottom: 100px;
}

.flyerPic {
  margin-left: auto!important;
  margin-right: auto!important;
  width: 50%;
}

@media (max-width: 1000px) {
  .videoContainer {
    flex-direction: column;
	}

	.video {
    width: 80%;
    height: 400px;
  }
}

@media (max-width: 600px) {
  .video {
    height: 250px;
  }
}

@media (max-width: 500px) {
  .video {
    height: 200px;
  }
}

@media (max-width: 380px) {
  .mediaSubHead {
    font-size: 2em;
  }
}
