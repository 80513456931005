nav {
	width: 100%;
  height: 50px;
  background: #15151E;
	top: 0;
}

ul {
	padding: 0;
	margin: 0;
	display: flex;
	justify-content: center;
}

li {
	margin-right: 20px;
	margin-left: 20px;
}

/* TODO: remove class and replace with div span */
.navbar_hamSpan__LVs-4 {
  background-color: #143DF5;
  width: 20px;
	height: 2px;
	margin: auto;
}

.navbar_ham__fLCx- {
  cursor: pointer;
  padding: 5px;
  margin-right: 15px;
  border: 1px solid #00C33C;
  height: 35px;
  width: 35px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
}
.navbar_dropNav__tAMGd {
	z-index: 10px;
	width: 100%;
  top: 0px;
  left: 0px;
  flex-direction: column;
  position: absolute;
  text-align: center;
  margin-top: 194px;
  background : #15151E;
  border-bottom: 1px solid #143DF5;
}
.navbar_navbarLogo__eKLmC {
  cursor: pointer;
  margin-top: 55px;
  height: 100px;
}
.navbar_mobileNavLogo__4XArv {
  cursor: pointer;
  height: 40px;
  padding-left: 10px;
  margin-top: auto;
  margin-bottom: auto;
}

.navbar_navLink__JiWg2:hover {
  color: #143DF5;
}

.navbar_navLink__JiWg2 {
  color: white;
  cursor: pointer;
  padding: 15px;
}

@media (max-width: 800px) {
  .navbar_navLogo2__1w04D {
    height: 70px;
  }
  .navbar_ham__fLCx- {
    width: 50px;
    height: 50px;
  }
  .navbar_hamSpan__LVs-4 {
    width: 30px;
  }
  nav {
    height: 100px;
  }
  .navbar_dropNav__tAMGd {
    margin-top: 100px;
	}
	.navbar_mobileNavLogo__4XArv {
		height: 75px;
	}
}
@media (max-width: 700px) {
  .navbar_navLogo2__1w04D {
    height: 50px;
  }
  .navbar_ham__fLCx- {
    width: 30px;
    height: 30px;
  }
  .navbar_hamSpan__LVs-4 {
    width: 20px;
  }
  nav {
    height: 50px;
  }
  .navbar_dropNav__tAMGd {
    margin-top: 49px;
	}
	.navbar_mobileNavLogo__4XArv {
		height: 40px;
	}
}

.footer_socialLink__SU7v6 {
	color: rgba(0, 0, 0, 0);
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px !important;
	width: 100px !important;
}

.footer_socials__123to {
  height: 50px;
  width: 300px;
}

.footer_icon__31mmi {
  color: white;
}

footer {
  margin-top: auto;
  width: 100%;
  background-color: #15151E;
}

.footer_fa-superpowers__3VkWe {
  margin-left: 0;
}
.blog_blogs__3rJ_M {
  width: 700px;
  margin-bottom: 100px;
}

.blog_lineItem__3g3fI {
  height: 40px;
  color: #00C33C;
	justify-content: flex-start;
	width: 100%;
	display: flex;
	cursor: pointer;
	align-items: center;
	flex-direction: row;
}

.blog_lineItem__3g3fI:hover {
  color: silver;
  background: #15151D;
}

.blog_mainPost__1rGUt {
  width: 600px;
  display: flex;
  padding: 25px;
  cursor: pointer;
  min-height: 500px;
  background-color: #15151E;
  margin-bottom: 50px;
  border-radius: 7px;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.blog_blogs__3rJ_M {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  width: 700px;
  margin-bottom: 100px;
}

.blog_mainPost__1rGUt {
  width: 600px;
  display: flex;
  padding: 25px;
  cursor: pointer;
  min-height: 500px;
  background-color: #15151E;
  margin-bottom: 50px;
  border-radius: 7px;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.blog_blogLineItemName__zfe1V {
	padding-left: 10px;
}

.blog_singlePost__3o0bL {
  width: 600px;
  display: flex;
  padding: 25px;
  cursor: pointer;
  min-height: 500px;
  background-color: #15151E;
  margin-bottom: 50px;
  border-radius: 7px;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.blog_blogImage__mNJyI {
  width: 100%;
}

.blog_editBlogImage__1c5VM {
  padding-bottom: 35px;
  width: 100%;
}

.blog_locationDateContainer__2nFNi {
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
}

.blog_title__3vHPe {
  font-size: 30px;
  font-family: orbitron;
  padding-top: 20px;
  padding-bottom: 20px;
  color: white;
}

.blog_locDate__3zFu5 {
  margin-right: 10px;
  color: white;
  font-size: 25px;
}

.blog_setTitle__cTS2U {
  color: white;
  font-size: 25px;
  padding-bottom: 15px;
  font-family: orbitron;
}

.blog_content__2X2MS {
  width: 95%;
  display: flex;
  flex-direction: column;
}

.blog_blogItem__2r7Ju {
  color: white;
  font-size: 20px;
  padding-bottom: 10px;
}

form {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  width: 600px;
	margin-bottom: 100px;
	flex-direction: column;
}

textarea {
  height: 250px;
	padding: 6px 12px;
	padding-left: 20px;
	border: 1px solid #ccc;
	width: 90%;
  margin-bottom: 35px;
  border-radius: 3px;
}

.blog_panel__lSF5W {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}

.blog_blogContainer__2W4eW {
  border-radius: 5px;
  overflow-y: auto;
  max-height: 400px;
  border: 1px solid #143DF5;
  justify-content: flex-start;
}

.blog_preview__3b719 {
	width: 200px;
}

@media (max-width: 800px) {
	form {
		width: 100%;
	}
}

@media (max-width: 700px) {
  .blog_blogs__3rJ_M {
    width: 100%;
	}

	.blog_mainPost__1rGUt {
    width: 90%;
  }
}

@media (max-width: 650px) {
  .blog_detail__1tvub {
    font-size: 12px;
  }
}

p {
	margin: 0;
}

.bookingInfo_booking__2i3nH {
  padding-bottom: 50px;
}

.bookingInfo_bookingHeader__Xa790 {
  font-size: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #00C33C;
}

.bookingInfo_bookingText__1kDdD {
  color: white;
  padding-top: 5px;
  padding-bottom: 5px;
}

.bookingInfo_blogForm__3G6Hh {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  width: 600px;
  margin-bottom: 100px;
  flex-direction: column;
}

input {
  padding-left: 20px;
  background-color: #EFEFEF;
  width: 100%;
  margin-bottom: 35px;
	border-radius: 3px;
	height: 45px;
}

.bookingInfo_textArea__RKmxX {
  height: 250px;
	padding: 6px 12px;
	padding-left: 20px;
	border: 0;
	width: 100%;
  margin-bottom: 35px;
	border-radius: 3px;
	background: #212B37;
	color: #00C33C;
	font-size: 20px;
}

.bookingInfo_contactButton__3oRPk {
	margin-top: 30px;
}

@media (max-width: 800px) {
  .bookingInfo_textArea__RKmxX {
    width: 80%;
  }
}
.bandsInTown_tour__asjJw {
  width: 80%;
}

@media (max-width: 1200px) {
  .bandsInTown_tour__asjJw {
    width: 90%;
  }
}

.about_bioText__rdNqp {
  text-align: center;
  color: white;
  font-family: Play;
  font-size: 20px;
}

section {
  width: 70%;
}

.about_familyPhoto__1PjV7 {
  width: 100%;
  margin-bottom: 100px;
}

@media (max-width: 500px) {
  section {
    width: 90%;
  }
}

@media (max-width: 450px) {
  .about_bioText__rdNqp {
    font-size: 15px;
  }
}

.media_videoContainer__Q09na {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
}

.media_video__34M0J {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
  height: 315px;
  width: 40%;
}

h3 {
  text-align: center;
  color: #C4C4C4;
  font-family: Orbitron;
  margin-bottom: 100px;
  margin-top: 0;
}

.media_flyerCarousel__ewiGT {
  margin-bottom: 100px;
}

.media_flyerPic__1GVrF {
  margin-left: auto!important;
  margin-right: auto!important;
  width: 50%;
}

@media (max-width: 1000px) {
  .media_videoContainer__Q09na {
    flex-direction: column;
	}

	.media_video__34M0J {
    width: 80%;
    height: 400px;
  }
}

@media (max-width: 600px) {
  .media_video__34M0J {
    height: 250px;
  }
}

@media (max-width: 500px) {
  .media_video__34M0J {
    height: 200px;
  }
}

@media (max-width: 380px) {
  .media_mediaSubHead__3RFv1 {
    font-size: 2em;
  }
}

h3 {
  text-align: center;
  color: white;
  font-family: Play;
}

.music_musicIframe__J__c9 {
  height: 450px;
}

.music_musicContent__3UGRM {
  width: 80%;
  margin-bottom: 100px;
}

.music_musicRow__37D9y {
  display: flex;
  flex-direction: row;
}

section {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 1100px) {
  .music_musicContent__3UGRM {
    width: 90%;
  }
}

@media (max-width: 900px) {
  .music_musicRow__37D9y {
    flex-direction: column;
	}

	section {
    width: 80%;
	}

	h3 {
    margin-bottom: 40px;
    margin-top: 40px;
  }
}

@media (max-width: 450px) {
  section {
    width: 90%;
  }
}

#epk_darkDiv__20OvN {
  background-color:rgba(0, 0, 0, 0.7);
  position: relative;
  padding-top: 120px;
}

.epk_padding0__OAsow {
  padding: 0;
}

.epk_pS20__3dP8x {
  padding-left: 20px;
  padding-right: 20px;
}

.epk_icon__iKwIw {
  color: white;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
}

.epk_icon-div__2vOZ3 {
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 30px;
  height: 50px;
  width: 50px;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  display: flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
}
.epk_hyphon__dU_TT {
  margin-left: 5px;
  margin-right: 5px;
}
.epk_icon-div__2vOZ3:hover {
  background: linear-gradient(to right, #64549A, #08A975);;
}

.epk_icon-logo__2gkhr {
  margin-left: 5px;
  margin-right: 5px;
}
.epk_button__2xR7I {
  margin-left: auto;
  margin-right: auto;
}
.epk_footer-text__3OnUc {
  color: white;
  font-size: 20px;
  font-family: exo-medium;
  margin: 0;
}
.epk_wysidio-link__1wv3r {
  margin-left: 5px;
  text-decoration: none;
  color: #367F87;
  font-size: 20px;
  font-family: exo-medium;
}
.epk_rowCol__1URV_ {
  display: flex;
  flex-direction: row;
}
.epk_mSA__3t_ep {
  margin-left: auto;
  margin-right: auto;
}
.epk_mainEpk__21mVl {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  font-family: exo-light;
}
.epk_song1__3O8Va {
  height: 250px;
  width: 40%;
  display: flex;
}
.epk_section__1oG3z {
  padding-top: 30px;
}
.epk_pT30__MYy33 {
  padding-top: 30px;
}
.epk_secHead__v20ic {
  margin: 0;
  border: none;
  color: #E0E0E0;
  font-size: 40px;
  text-align: center;
  font-family: exo-light;
}
.epk_list__BNHRX {
  color: #E0E0E0;
  font-size: 25px;
  margin: 0;
}
.epk_bigBullet__2Gxhz {
  padding-bottom: 15px;
}
.epk_bioContent__1YxrC {
  color: #E0E0E0;
  font-size: 18px;
}
.epk_bioHeader__ry9tr {
  margin: 0;
  color: #E0E0E0;
  font-size: 35px;
}
.epk_mediaLink__3ovqb {
  color: #367F87;
  font-size: 25px;
}
#epk_logoImg__vpAk_ {
  width: 80%;
  display: flex;
  padding-top: 100px;
  padding-bottom: 100px
}
.epk_title__1c44T {
  border-top: 2px solid rgba(255, 255, 255, .5);
  height: 99px;
  border-bottom: 2px solid rgba(255, 255, 255, .5);
}
.epk_footer__Fe4Jx {
  color: #E0E0E0;
  padding-bottom: 30px;
}
.epk_wysidio__mX7Lp {
  width: 50px;
}
.epk_press__20vPI {
  border-bottom: 2px solid rgba(255, 255, 255, .5);
}
#epk_epkTitle__1uYPq {
  text-align: center;
  color: white;
  margin-top: 30px;
  margin-bottom: 30px;
  font-family: exo-medium;
  font-size: 30px;
}
.epk_pressPic__2pul8 {
  display: flex;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
}
.epk_social__2J_Uk {
  padding-bottom: 30px;
  padding-top: 30px;
  align-items: center;
  justify-content: flex-end;
}
.epk_socialLink__29QKS {
  color: rgba(0, 0, 0, 0);
}
#epk_logoHeader__OQu6q {
  width: 100%;
  margin-bottom: 60px;
}
.epk_pressKitDl__2NZP_ {
  cursor: pointer;
  width: 400px;
  display: flex;
  flex-direction: column;
  color: rgba(0, 0, 0, 0);
  margin-bottom: 25px;
}
.epk_download__2aAse {
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  width: 400px;
  height: 80px;
  border-radius: 8px;
  background: linear-gradient(to right, #64549A, #08A975);
}
.epk_hvrGrow__3FiFV {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    transition-duration: 0.3s;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
}
.epk_hvrGrow__3FiFV:hover,
.epk_hvrGrow__3FiFV:focus,
.epk_hvrGrow__3FiFV:active {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    box-shadow: -1px 1px 84px 0px rgba(255,255,255,0.79);
}
.epk_button-text__3VoZ2 {
  color: silver;
  font-size: 20px;
  text-align: center;
}
.epk_album__17AMh {
  height: 400px;
  width: 100%;
}
@media (max-width: 1250px) {
  .epk_rowCol__1URV_ {
    flex-direction: column;
  }
  .epk_song1__3O8Va {
    width: 100%;
    height: 450px;
  }
  .epk_pT50Col__1dmCg {
    margin-bottom: 50px;
  }
  .epk_social__2J_Uk {
    justify-content: center;
  }
}
@media (max-width: 900px) {
  .epk_mediaLink__3ovqb {
    font-size: 20px;
  }
}
@media (max-width: 800px) {
  .epk_mainEpk__21mVl {
    width: 80%;
  }
  .epk_song1__3O8Va {
    width: 100%;
    height: 400px;
  }
  .epk_button-text__3VoZ2 {
    font-size: 16px;
  }
}
@media (max-width: 600px) {
  .epk_mainEpk__21mVl {
    width: 90%;
  }
  .epk_pressPic__2pul8 {
    width: 100%;
  }
  .epk_song1__3O8Va {
    width: 100%;
  }
  #epk_logo1__2_ZSP {
    width: 90%;
  }
  .epk_mediaLink__3ovqb {
    font-size: 14px;
  }
  #epk_logoHeader__OQu6q {
    height: 350px;
  }
  .epk_button-text__3VoZ2 {
    font-size: 13px;
  }
  .epk_download__2aAse {
    width: 80%;
    height: 50px;
  }
}
@media (max-width: 450px) {
  .epk_icon-div__2vOZ3 {
    width: 30px;
    height: 30px;
  }
  .epk_mediaLink__3ovqb {
    font-size: 11px;
  }
  .epk_secHead__v20ic {
    font-size: 25px;
  }
  .epk_list__BNHRX {
    font-size: 14px;
  }
  .epk_song1__3O8Va {
    width: 80%;
    height: 250px;
  }
  #epk_epk-title__26fcn {
    font-size: 25px;
  }
}
@media (max-width: 400px) {
  .epk_mediaLink__3ovqb {
    font-size: 10px;
  }
  .epk_list__BNHRX {
    font-size: 12px;
  }
}

@media (max-width: 320px) {
  .epk_secHead__v20ic {
    font-size: 20px;
  }
  .epk_bioContent__1YxrC {
    font-size: 15px;
  }
}

.detail_detailWhat__1kxSD {
	padding-left: 15px;
}
.iframe_iFrame__aYdNP {
  width: 90%;
  height: 300px;
  padding: 15px;
  color: #00C33C;
  font-size: 20px;
  margin-top: 20px;
  border-radius: 5px;
  background: #15151D;
  border: 1px solid #143DF5;
}
.login_vh100__2jk_K {
  height: 100vh;
}

.login_login__2dbcz {
	width: 80%;
	display: flex;
}

input {
	color: #00C33C;
	width: 100%;
	height: 40px;
	padding: 10px 20px;
	border-radius: 5px;
	background: #212B37;
	border: none;
}

button:hover {
	border: 1px solid rgb(111, 10, 255);
	color: rgb(12, 202, 53);
	background: rgba(0, 0, 0, 0.1);
}

button {
	cursor: pointer;
	border: none;
	height: 35px;
	width: 100%;
	border-radius: 25px;
	background: linear-gradient(296deg, #00C33C, #143DF5);
}

@media (max-width: 800px) {
  input {
    width: 80%;
	}
	
	button {
		width: 80%;
	}
}

