p {
	margin: 0;
}

.booking {
  padding-bottom: 50px;
}

.bookingHeader {
  font-size: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #00C33C;
}

.bookingText {
  color: white;
  padding-top: 5px;
  padding-bottom: 5px;
}

.blogForm {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  width: 600px;
  margin-bottom: 100px;
  flex-direction: column;
}

input {
  padding-left: 20px;
  background-color: #EFEFEF;
  width: 100%;
  margin-bottom: 35px;
	border-radius: 3px;
	height: 45px;
}

.textArea {
  height: 250px;
	padding: 6px 12px;
	padding-left: 20px;
	border: 0;
	width: 100%;
  margin-bottom: 35px;
	border-radius: 3px;
	background: #212B37;
	color: #00C33C;
	font-size: 20px;
}

.contactButton {
	margin-top: 30px;
}

@media (max-width: 800px) {
  .textArea {
    width: 80%;
  }
}