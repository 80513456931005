.iFrame {
  width: 90%;
  height: 300px;
  padding: 15px;
  color: #00C33C;
  font-size: 20px;
  margin-top: 20px;
  border-radius: 5px;
  background: #15151D;
  border: 1px solid #143DF5;
}