h3 {
  text-align: center;
  color: white;
  font-family: Play;
}

.musicIframe {
  height: 450px;
}

.musicContent {
  width: 80%;
  margin-bottom: 100px;
}

.musicRow {
  display: flex;
  flex-direction: row;
}

section {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 1100px) {
  .musicContent {
    width: 90%;
  }
}

@media (max-width: 900px) {
  .musicRow {
    flex-direction: column;
	}

	section {
    width: 80%;
	}

	h3 {
    margin-bottom: 40px;
    margin-top: 40px;
  }
}

@media (max-width: 450px) {
  section {
    width: 90%;
  }
}
