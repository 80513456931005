.blogs {
  width: 700px;
  margin-bottom: 100px;
}

.lineItem {
  height: 40px;
  color: #00C33C;
	justify-content: flex-start;
	width: 100%;
	display: flex;
	cursor: pointer;
	align-items: center;
	flex-direction: row;
}

.lineItem:hover {
  color: silver;
  background: #15151D;
}

.mainPost {
  width: 600px;
  display: flex;
  padding: 25px;
  cursor: pointer;
  min-height: 500px;
  background-color: #15151E;
  margin-bottom: 50px;
  border-radius: 7px;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.blogs {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  width: 700px;
  margin-bottom: 100px;
}

.mainPost {
  width: 600px;
  display: flex;
  padding: 25px;
  cursor: pointer;
  min-height: 500px;
  background-color: #15151E;
  margin-bottom: 50px;
  border-radius: 7px;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.blogLineItemName {
	padding-left: 10px;
}

.singlePost {
  width: 600px;
  display: flex;
  padding: 25px;
  cursor: pointer;
  min-height: 500px;
  background-color: #15151E;
  margin-bottom: 50px;
  border-radius: 7px;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.blogImage {
  width: 100%;
}

.editBlogImage {
  padding-bottom: 35px;
  width: 100%;
}

.locationDateContainer {
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
}

.title {
  font-size: 30px;
  font-family: orbitron;
  padding-top: 20px;
  padding-bottom: 20px;
  color: white;
}

.locDate {
  margin-right: 10px;
  color: white;
  font-size: 25px;
}

.setTitle {
  color: white;
  font-size: 25px;
  padding-bottom: 15px;
  font-family: orbitron;
}

.content {
  width: 95%;
  display: flex;
  flex-direction: column;
}

.blogItem {
  color: white;
  font-size: 20px;
  padding-bottom: 10px;
}

form {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  width: 600px;
	margin-bottom: 100px;
	flex-direction: column;
}

textarea {
  height: 250px;
	padding: 6px 12px;
	padding-left: 20px;
	border: 1px solid #ccc;
	width: 90%;
  margin-bottom: 35px;
  border-radius: 3px;
}

.panel {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}

.blogContainer {
  border-radius: 5px;
  overflow-y: auto;
  max-height: 400px;
  border: 1px solid #143DF5;
  justify-content: flex-start;
}

.preview {
	width: 200px;
}

@media (max-width: 800px) {
	form {
		width: 100%;
	}
}

@media (max-width: 700px) {
  .blogs {
    width: 100%;
	}

	.mainPost {
    width: 90%;
  }
}

@media (max-width: 650px) {
  .detail {
    font-size: 12px;
  }
}
