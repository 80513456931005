.vh100 {
  height: 100vh;
}

.login {
	width: 80%;
	display: flex;
}

input {
	color: #00C33C;
	width: 100%;
	height: 40px;
	padding: 10px 20px;
	border-radius: 5px;
	background: #212B37;
	border: none;
}

button:hover {
	border: 1px solid rgb(111, 10, 255);
	color: rgb(12, 202, 53);
	background: rgba(0, 0, 0, 0.1);
}

button {
	cursor: pointer;
	border: none;
	height: 35px;
	width: 100%;
	border-radius: 25px;
	background: linear-gradient(296deg, #00C33C, #143DF5);
}

@media (max-width: 800px) {
  input {
    width: 80%;
	}
	
	button {
		width: 80%;
	}
}
